import axios from "axios";
import { API_URL } from "../../../.env";
import { auth } from "@/core/services/auth";

const URL_V1 = `${API_URL}/api/v1`;

const formDataHeaders = {
  headers: { "Content-Type": "multipart/form-data" },
};

export default {
  getToken(id) {
    const path = `${URL_V1}/login/get-token?id=${id}`;
    return axios.get(path);
  },

  get(path) {
    return axios.get(path, auth.getHeaders());
  },

  post(path, payload, multy = false) {
    // fix this shit with header multy
    return axios.post(path, payload, auth.getHeaders(multy));
  },

  put(path, payload) {
    return axios.put(path, payload, auth.getHeaders());
  },

  delete(path, payload) {
    return axios.delete(path, payload, auth.getHeaders());
  },

  ///////////////////////////////////////////////////////

  getUser() {
    const path = `${URL_V1}/users/me`;
    return this.get(path);
  },

  saveUser(jsonData) {
    const path = `${URL_V1}/users/me`;
    return axios.put(path, jsonData, auth.getHeaders());
  },

  getAccessToken(formData) {
    const path = `${URL_V1}/login/access-token`;
    return axios.post(path, formData, formDataHeaders);
  },

  getAuthTokenByCert(jsonData) {
    const path = `${URL_V1}/openid/signature_auth`;
    return axios.post(path, jsonData);
  },

  createUser(user) {
    const path = `${URL_V1}/users/`;
    return axios.post(path, user);
  },

  sendActivationSms(jsonData) {
    const path = `${URL_V1}/request_phone_confirmation`;
    return this.post(path, jsonData);
  },

  sendCheckSmsCode(jsonData) {
    const path = `${URL_V1}/phone_confirmation`;
    return this.post(path, jsonData);
  },

  sendEmailActivation(jsonData) {
    const path = `${URL_V1}/request_email_confirmation`;
    return this.post(path, jsonData);
  },
};
